<template>
  <div class="container-fluid">
    <h2 class="my-h2">Consultoría</h2>
    <h3 class="my-h3">
      ¿Cómo podemos ayudarte en la aplicación de la PEG?
    </h3>
    <div class="general-text">
      <p>
        A través de capacitaciones, asesoría y análisis con amplia experiencia
        en sistemas de gestión de Igualdad y No Discriminación, contamos con
        estudios e investigaciones sobre el fenómeno de violencia de género,
        cultura institucional con perspectiva de género en los ámbitos federal,
        estatal y municipal, modelos de capacitación para la igualdad entre
        hombres y mujeres, así como en instrumentar protocolos de atención de la
        violencia.
      </p>
      <p>
        Para la realización de las acciones y/o proyectos, nos vinculamos a los
        mandatos del Plan Nacional de Desarrollo 2019-2024, que establece en el
        eje 2 (<b>Bienestar</b>) y eje transversal (<b
          >Igualdad de Género, No Discriminación e Inclusión</b
        >), la vía de la implementación de la perspectiva de género en el país.
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "CapacitacionView",
};
</script>
        
<style>
</style>