<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Norma Mexicana NMX-R-SCFI-2015; herramienta de competitividad
          empresarial e institucional.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Norma Mexicana NMX-R-SCFI-2015; herramienta de competitividad empresarial
      e institucional.
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/norma_m.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          El instituto Nacional de las Mujeres (Inmujeres) define la
          <b
            >Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No
            Discriminación</b
          >
          como un mecanismo de adopción voluntaria para reconocer a los centros
          de trabajo que cuentan con prácticas en materia de igualdad laboral y
          no discriminación, que busca favorecer el desarrollo integral de las y
          los trabajadores.
        </p>
        <p>
          Esta herramienta permite generar condiciones de competitividad en un
          centro de trabajo (público, privado o social), generando valor
          agregado en los procesos, productos y servicios, pero poniendo como
          prioridad la dignificación de condiciones laborales. Debemos entender
          que ante el contexto nacional e internacional se vuelve indispensable,
          para cualquier centro de trabajo, certificarse en esta norma, sea del
          tamaño que sea o la actividad que desempeñe.
        </p>
        <p>
          Certificar los servicios y productos se ha vuelto insuficiente para
          decir que un organismo cuenta con una gestión de calidad orientada al
          cumplimiento de las demandas de las partes involucradas
          (steakholders), se requiere mejorar los procesos internos del centro
          de trabajo, y esto sólo se logra con un personal comprometido a
          cumplir con los objetivos institucionales. Pero, ¿cómo se logra que el
          personal adopte una actitud de compromiso? Fácil, apoyándole a que
          logre una corresponsabilidad entre su vida laboral, familiar y
          personal.
        </p>
        <p>
          Muchos pensarían que la norma de igualdad y no discriminación solo
          beneficia al personal, sin embargo, el impacto que tiene es tan
          profundo que logra crear ventajas transversales:
        </p>
        <p>
          <b>En lo social:</b>
        </p>
        <ul>
          <li>
            Ratifica el compromiso que tiene un centro de trabajo en ser
            socialmente responsable, pues responde a necesidades actuales de
            respetar, promover y proteger los derechos humanos.
          </li>
          <li>
            Promueve el fortalecimiento de la cultura interna del centro de
            trabajo y el respeto por la diversidad.
          </li>
          <li>
            Permite disminuir la incidencia de prácticas discriminatorias y de
            violencia, además de que genera mecanismos que facilitan la
            resolución y tratamiento de dichas prácticas
          </li>
        </ul>
        <p>
          <b>En el Personal:</b>
        </p>
        <ul>
          <li>
            Promueve el equilibrio entre las responsabilidades familiares,
            laborales y personales.
          </li>
          <li>
            Genera mayor compromiso, lealtad e identidad hacia la organización
            por parte del personal.
          </li>
          <li>
            Suscita un ambiente de trabajo a favor de la diversidad, igualdad e
            inclusión, lo que atrae y retiene el talento.
          </li>
        </ul>
        <p>
          <b>En el centro de trabajo:</b>
        </p>
        <ul>
          <li>
            Lo vuelve competitivo ante la demanda de estándares nacionales e
            internacionales.
          </li>
          <li>
            En lo privado Incrementa su valor ante el mercado y en lo público
            obtiene puntos para participar en procesos adquisitivos
            gubernamentales.
          </li>
          <li>
            Fortalece la lealtad del personal, lo que a largo plazo se ve
            reflejado en los ingresos, pues se optimizan los recursos humanos
            del centro de trabajo.
          </li>
          <li>El personal se vuelve más participativo y productivo.</li>
        </ul>
        <p>
          Muchos son los discursos sobre igualdad, falta de condiciones
          laborales y discriminación, pero la verdad es que muy pocas veces se
          reflexiona en torno a las herramientas que logran aminorar estas
          problemáticas de manera sustancial. Basta de replicar discursos que
          reiteren el crecimiento desmedido de estas problemáticas -eso lo
          sabemos- es momento de promover la existencia de mecanismos
          fundamentales para lograr ambientes laborales dignos, libres de
          discriminación y desigualdad.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>