<template>
  <div class="container-fluid">    
    <div class="col-12 table-title">
              Nombre del Taller: Herramientas para la mejora continua del centro de trabajo una vez certificado en Norma Mexicana NMX-R-025-SCFI-2015
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>
                    Facilitar instrumentos sustentados en sistemas de gestión de calidad que permitan mejorar en un centro de trabajo la presentación de la documentación que sustenta la evidencia del cumplimiento de los requisitos que establece la Norma Mexicana NMX-R-025-SCFI-2015 para mantener la renovación de la certificación.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso proporcionará a los asistentes herramientas de trabajo que faciliten la mejora de la presentación de la documentación que se requiere como evidencia para conseguir la renovación de certificación en la Norma Mexicana NMX-R-025-SCFI-2015.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Profesionistas, independientes, consultores, académicos, estudiantes, la alta dirección y personal de los centros de trabajo públicos, privados y sociales que estén interesados en renovar su certificado en la Norma Mexicana NMX-R-025-SCFI-2015.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Registro.</li>
                  <li>Cartas de no Incumplimiento.</li>
                  <li>Ejemplo de política.</li>
                  <li>Acta de instalación del Grupo, Comisión o Comité.</li>
                  <li>Lineamientos de operación del Grupo, Comisión o Comité.</li>
                  <li>Proceso de reclutamiento y selección.</li>
                  <li>Esquema de proceso de reclutamiento y selección.</li>
                  <li>Apéndice C Diagnóstico de autoevaluación.</li>
                  <li>Cuestionario de percepción de clima laboral.</li>
                  <li>
                    Elementos del Código de Ética.<br/>
                    Tabulador de sueldos.<br/>
                    Procedimiento de promoción y ascenso.
                  </li>
                  <li>Proceso de formación de capacitación.</li>
                  <li>Programa anual de formación de capacitación.</li>
                  <li>
                    Manual del uso no sexista del lenguaje.<br/>
                    Recomendaciones para el uso no sexista del lenguaje.<br/>
                    Corresponsabilidad.
                  </li>
                  <li>Accesibilidad en los centros de trabajo.</li>
                  <li>Mecanismo de atención a las prácticas de discriminación.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>6 días 12 horas (2 por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 3 al 5 y del 10 al 12 de julio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  