<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          La igualdad sustantiva de género empieza en el diseño de políticas
          públicas, no el discurso.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      La igualdad sustantiva de género empieza en el diseño de políticas
      públicas, no el discurso.
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/startup.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          En 2016, el Instituto Nacional de Estadística y Geografía (INEGI)
          realizó la encuesta “Mujeres y hombres en México”, la cual reflejó que
          8 de cada 10 hombres son económicamente activos, mientras que sólo 4
          mujeres de cada 10 lo son. Por otra parte, de acuerdo con un reporte
          del Instituto Mexicano del Seguro Social (IMSS), en 2019 los hombres
          percibían un salario promedio diario de $398, $52 pesos más que las
          mujeres. Estos datos permiten tener un punto de referencia para
          entender la brecha de género que existe en México, que, dadas las
          condiciones actuales, se va ampliando día con día, y cada vez resulta
          más complicado mejorar sus condiciones de vida.
        </p>
        <p>
          El papel activo de la mujer en México nunca se ha reconocido, si
          embargo, en las últimas décadas se ha vuelto fundamental en el cambio
          de la vida política, social y económica del país, si bien esta lucha
          ha tenido un impacto real en la manera en que esta configurada la
          estructura social del país, no ha sido suficiente para garantizar una
          igualdad plena entre hombre y mujeres. Queda claro que existen una
          voluntad activa de la ciudadana por erradicar esta brecha de
          desigualdad, sin embargo, aún quedan dudas sobre la eficiencia de las
          estrategias que han implementado los gobiernos (locales, estatales y
          federal), para atender las demandas legítimas de desigualdad en
          materia de género, pues pareciera que son estrategias con un enfoque
          meramente político, dejando totalmente de lado a la administración
          pública para el logro de la igualdad sustantiva de género.
        </p>
        <p>
          El rol de la administración pública es esencial para lograr la
          igualdad sustantiva de género, pues a partir de ésta es que se diseñan
          las políticas públicas, mismas que hasta ahora, en su mayoría, no han
          reflejado un cambio sustancial en la brecha de desigualdad, siendo
          esto un llamado a las servidoras y servidores públicos, para generar
          nuevos enfoques en la gestión de políticas públicas y programas. Las
          políticas públicas con perspectiva de género son entendidas como las
          acciones gubernamentales, diseñadas con una metodología que permite
          identificar, cuestionar, valorar la discriminación, desigualdad y
          exclusión de todo ser humano. Gestionar una política con perspectiva
          de género, garantiza que, desde su análisis, diseño, metodología,
          ejecución, resultados y evaluación, se oriente el proceso al logro de
          la igualdad sustantiva de género. Sin duda los enfoques de género en
          la agenta pública y administrativa no son algo nuevo, sin embargo, han
          venido evolucionando:
        </p>
        <ol>
          <li>
            Primera generación: En este primer momento, las políticas públicas
            reconocen el papel activo de la mujer en la vida política.
          </li>
          <li>
            Segunda generación: Ya no solo se reconoce a la mujer, sino que se
            visibiliza a los grupos de la diversidad sexual, como grupos
            vulnerables, permitiendo que el diseño de programas se oriente a la
            atención de sus demandas.
          </li>
          <li>
            Tercera generación: Actualmente, la sociedad demanda este nivel de
            políticas públicas con perspectiva de género, consideradas como
            efectivas y transversales, pues garantizan un impacto real en la
            reducción de la brecha de género, siendo que actúan de manera
            transversal en los aspectos políticos, económicos, y sociales. Un
            ejemplo de esto es la condonación parcial o total en el pago de
            servicios públicos, a mujeres jefas de familia.
          </li>
        </ol>
        <p>
          En Visión y Estreategia hemos desarrollado un taller para el diseño de
          programas municipales con perspectiva de género, con el fin de brindar
          a las administraciones públicas los elementos teóricos, técnicos y
          prácticos para un correcto diseño y ejecución de este tipo de
          políticas públicas.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>