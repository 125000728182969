<template>
  <div class="container-fluid">
    <!--Animated title-->
    <div class="animate-div">
      <p class="animate__animated animate__slideInDown">Nosotros</p>
    </div>
    <!--Animated title end-->
    <div class="row mb-5 mt-1">
      <div class="col-12 py-2">
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">
              Dirección Estratégica de Visión y Estrategia
            </h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Visión y Estrategia nace con el firme convencimiento de incitar y
              fortalecer la calidad, innovación y resultados en los servicios
              que brindamos en las instituciones públicas y privadas, ya que, al
              dar confianza a los clientes, usuarios directivos, empresas,
              gobierno y demás partes interesadas, sobre nuestros servicios,
              contribuimos al desarrollo y competitividad de las instituciones
              públicas y privadas de México. Como parte de su estructura
              organizacional, Visión y Estrategia cuenta con una perspectiva,
              misión y valores corporativos que rigen el funcionamiento interno
              de cada una y uno de sus integrantes.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 py-2">
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Misión</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Suscitar resultados mediante servicios profesionales con personal
              altamente competente, que ofrece entre nuestros clientes un
              ambiente de confianza; el mejor servicio, calidad y generando
              valor público.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 py-2">
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Visión</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Ser en el mercado de la consultoría y capacitación un referente
              distintivo por nuestras capacidades innovadoras que nos definirá
              como una marca exitosa en los servicios otorgados.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 py-2">
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Política de calidad</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Satisfacer las necesidades y expectativas de nuestros clientes y
              las partes interesadas, a través de la promoción de resultados y
              prestación de servicios profesionales altamente competentes, de
              calidad y servicio; generando valor agregado, contribuyendo con
              ello al desarrollo y competitividad de nuestros usuarios para que
              permita una mejora continua en un ambiente de confianza con el
              esfuerzo de todos los que colaboramos en Visión y Estrategia.
            </p>
          </div>
        </div>
      </div>
    </div>

    <h2 class="my-h2">Nuestros Valores</h2>
    <div class="general-text row justify-content-center">
      <div
        class="col-sm-4 col-md-4 col-lg-3 col-xl-2 col-6"
        style="text-align: center"
      >
        <img
          src="@/assets/img/valores/honestidad.jpg"
          alt=""
          class="center img-fluid"
        />
        <p>Honestidad</p>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-3 col-xl-2 col-6"
        style="text-align: center"
      >
        <img
          src="@/assets/img/valores/responsabilidad.jpg"
          alt=""
          class="center img-fluid"
        />
        <p>Responsabilidad</p>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-3 col-xl-2 col-6"
        style="text-align: center"
      >
        <img
          src="@/assets/img/valores/respeto.jpg"
          alt=""
          class="center img-fluid"
        />
        <p>Respeto</p>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-3 col-xl-2 col-6"
        style="text-align: center"
      >
        <img
          src="@/assets/img/valores/compromiso.jpg"
          alt=""
          class="center img-fluid"
        />
        <p>Compromiso</p>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-3 col-xl-2 col-6"
        style="text-align: center"
      >
        <img
          src="@/assets/img/valores/verdad.jpg"
          alt=""
          class="center img-fluid"
        />
        <p>Verdad</p>
      </div>
      <div
        class="col-sm-4 col-md-4 col-lg-3 col-xl-2 col-6"
        style="text-align: center"
      >
        <img
          src="@/assets/img/valores/empatia.jpg"
          alt=""
          class="center img-fluid"
        />
        <p>Empatía</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NosotrosPage",
};
</script>

<style>
</style>