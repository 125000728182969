<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Lenguaje incluyente y lenguaje no sexista, ¿Son lo mismo?
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Lenguaje incluyente y lenguaje no sexista, ¿Son lo mismo?
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/braille.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b>
            <em>
              “Un señor de Guerrero contó que al llegar a Cuernavaca le fue muy
              difícil encontrar trabajo, ya que no tenía sus papeles y le
              costaba mucho trabajo hacer los trámites debido a su apariencia y
              porque hablaba lengua indígena”. Prontuario ENADIS 2017.
            </em>
          </b>
        </p>
        <p>El origen de su uso: somos una sociedad que discrimina.</p>
        <p>
          En México, seis de cada diez personas de 18 años y más consideran que
          la mayoría de las y los jóvenes son irresponsables (60.3%), casi la
          mitad piensa que mientras más religiones se permitan en el país, habrá
          más conflictos sociales (44.7%), cuatro de cada diez están de acuerdo
          con que los pobres se esfuerzan poco por salir de su pobreza (39.1%),
          una de cada tres personas piensa que convivir con personas con sida o
          VIH siempre es un riesgo (35.2%) y una proporción similar opina que la
          pobreza de las personas indígenas se debe a su cultura (34.1%).
          Asimismo, una de cada cuatro personas considera que las personas con
          discapacidad son de poca ayuda en el trabajo (24.5%).
        </p>
        <p>
          En un contexto de discriminación, el lenguaje incluyente se utiliza
          para dirigirse a la amplia diversidad de identidades culturales
          refiriendo con ello a la igualdad, la dignidad y el respeto que
          merecen todas las personas sin importar su condición humana y sin
          marcar una diferencia en la representación social de las poblaciones
          históricamente discriminadas evitando definirlas por sus
          características o condiciones.
        </p>
        <p>
          Manifiesta la diversidad social e intenta equilibrar las
          desigualdades, para forjar una sociedad que reconozca e integre a
          todos los grupos vulnerables (personas mayores de edad, mujeres
          embarazadas, personas de la comunidad LGBTI, personas con algún tipo
          de discapacidad, personas extranjeras, personas pertenecientes a
          minorías étnicas o pueblos indígenas).
        </p>
        <p>
          En cambio, el lenguaje no sexista refiere el uso de aquellas
          expresiones de la comunicación humana tendientes a visibilizar a ambos
          sexos, particularmente a las mujeres, eliminando la subordinación, la
          humillación y el uso de estereotipos, por lo que el lenguaje
          incluyente y lenguaje no sexista no son lo mismo, el primero no
          oculta, no subordina, no infravalora, no excluye y fomenta una cultura
          de respeto, el segundo fomenta la no violencia hacia las mujeres,
          nombra lo femenino y lo masculino, reemplaza palabras universales
          masculinas por genéricos neutros e identifica con el artículo femenino
          o masculino sustantivos invariables.
        </p>
        <p>
          Tanto el lenguaje incluyente como el no sexista son un medio para
          promover relaciones de respeto e igualdad entre los géneros,
          visibilizar a las mujeres, y prevenir la violencia y discriminación
          contra cualquier persona. El lenguaje incluyente lucha por la justicia
          e igualdad, no excluir a grupos vulnerables y no expresar
          reiteradamente relaciones desiguales, jerárquicas e inequitativas, el
          lenguaje no sexista desalienta estereotipos, estigmas y roles de
          género
        </p>
        <p>
          Utilizar ambos, es decir, el lenguaje incluyente y el no sexista, no
          va a eliminar inmediatamente la desigualdad para con los grupos
          vulnerables, no acabará con la discriminación o la exclusión, pero es
          una herramienta para que las mujeres y los grupos de población
          tradicional e históricamente excluidos sean nombrados y sean visibles.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>