<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Tres tipos de lenguaje para la vida: el accesible, el incluyente y el
          no sexista
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Tres tipos de lenguaje para la vida: el accesible, el incluyente y el no
      sexista
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/comunicacion.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b
            >“En el lenguaje del Siglo XX, la llegada a la luna fue un gran paso
            para el hombre, en el lenguaje del Siglo XXI, fue un gran paso para
            la humanidad”.</b
          >
        </p>
        <p>
          Desmontar estereotipos en el lenguaje cotidiano significa desmontar el
          Siglo XX, para avanzar, como en todos los aspectos de nuestra vida,
          con nuevas maneras de comunicarnos. De la misma manera como han
          evolucionado las telecomunicaciones y las nuevas técnicas para mejorar
          la salud, LA EVOLUCIÓN DEL LENGUAJE ES EQUIPARABLE A LA EVOLUCIÓN DE
          NUESTRA CULTURA.
        </p>
        <p>
          Todos los seres vivos poseen mecanismos de comunicación, en el caso
          del ser humano, se distingue por transmitir significados complejos a
          través de un sistema de signos: orales, escritos o gestuales, este
          sistema de signos se requiere para llegar al objetivo del
          entendimiento común y lo entendemos como
          <b>lenguaje.</b>
        </p>
        <p>
          El lenguaje obedece a un espacio y a un tiempo siempre contemporáneo.
          Es dinámico, cambiante, y ciertamente es capaz de avivar fuegos de
          exclusión e intransigencia reforzando injusticias, discriminación y
          estereotipos,
          <b
            >pero también puede contribuir a lograr la igualdad, pues no se
            trata de una herramienta inerte, acabada, sino de una energía en
            permanente transformación que evoluciona para responder a las
            necesidades de la sociedad que lo utiliza.</b
          >
        </p>
        <p>
          Sostener que debemos seguir teniendo el mismo lenguaje de hace 100
          años, equivale a justificar que nada debe evolucionar, por ejemplo,
          descalificar la evolución de los primates en personas.
        </p>
        <p>
          El desarrollo de la humanidad requiere cada vez más entendimiento,
          sigue un principio de accesibilidad, donde los signos y símbolos
          usados en el lenguaje sean comprensibles y aceptados para todas y
          todos a quienes va dirigido el mensaje del emisor.
        </p>
        <p>
          En un ambiente laboral el lenguaje está dirigido a mejorar la
          información y comunicación hacia el personal que lo conforma, se
          requiere una nueva forma de comunicación, más clara, abierta, fluida,
          concisa y de fácil entendimiento con la finalidad de eliminar
          cualquier barrera en la comunicación, a esto se le denomina
          <b>lenguaje accesible</b>. TODO MUNDO DEBE ENTENDER UN MEMORANDUM, NO
          SOLO QUIEN LO ESCRIBE.
        </p>
        <p>
          El lenguaje además expresa una compleja trama de dimensiones humanas
          que van desde lo cotidiano y práctico hasta lo simbólico; abarca
          sentimientos, mandatos, experiencias y ha sido también fuente de
          violencia simbólica, una herramienta a través de la cual se ha
          naturalizado la discriminación y la desigualdad que históricamente ha
          existido entre mujeres y hombres, que tiene su origen en los roles y
          estereotipos de género que limitan y encasillan a las personas
          partiendo de sus diferencias sexuales y biológicas, denominado como
          <b>lenguaje sexista</b>, el cual específicamente legitima y reproduce
          relaciones injustas que invisibilizan a las mujeres, prevaleciendo
          formas de expresión colectiva que las excluyen con formas lingüísticas
          que subordinan lo femenino a lo masculino. DEBEMOS SER CAPACES DE
          ESTABLECER EL LENGUAJE NO SEXISTA.
        </p>
        <p>
          El <b>lenguaje incluyente</b> es un elemento que reconoce a las
          mujeres y a los hombres tanto en lo hablado como en lo escrito,
          manifiesta la diversidad social e intenta equilibrar las
          desigualdades. El lenguaje incluyente contribuye a forjar una sociedad
          que reconozca e integre la diversidad, la igualdad y la igualdad de
          género, terminando con la discriminación al utilizar el género
          masculino de manera neutra, por ejemplo: “en la historia de la
          humanidad”.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>