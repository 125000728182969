<template>
  <div class="container-fluid">
    <div class="table-title">
      Nombre: Asesoría y certificación de Norma Mexicana NMX-R-025-SCFI-2015 en
      Igualdad Laboral y No Discriminación
    </div>
    <div class="table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Contemplar mediante el “Fortalecimiento a la transversalidad de la
          perspectiva de género”, el desarrollo de tres tipos de proyectos, que
          consideran la implementación de acciones en favor de la igualdad entre
          mujeres y hombres, de esta forma se pretende contribuir a la
          transversalización de la perspectiva de género en la gestión pública.
        </li>
      </ul>
    </div>
    <div class="table-blue">
      <b>Descripción:</b>
      <p>
        Con la asesoría se pretende atender la desigualdad en razón de género, a
        través de la ejecución de acciones en las dependencias públicas en
        materia de Igualdad Laboral y No Discriminación. Para esto, se hace uso
        de la Norma Mexicana MMX-R-025-SCFI-2015, siendo esta un mecanismo de
        adopción voluntaria para reconocer a los centros de trabajo que cuentan
        con prácticas en materia de igualdad laboral y no discriminación,
        favoreciendo de esta forma el desarrollo integral de las y los
        trabajadores en el ámbito público.
      </p>
    </div>
    <div class="table-white">
      <b>Características:</b>
      <p>
        <b>Dirigido a: </b>Personas interesadas en la aplicación y
        visibilización de la perspectiva de género.
      </p>
      <p>
        <b>Garantía de satisfacción: </b>Avalamos la transmisión de
        conocimientos a través de una metodología que permita elegir la
        información para el diseño de productos o resultados.
      </p>
      <p>
        <b>Constancia: </b>Al término del curso se otorgará una constancia de
        participación con valor curricular, con la insignia de Visión y
        Estrategia.
      </p>
    </div>
    <div class="table-blue">
      <b>Temario:</b>
      <ol>
        <li>Asesoría y certificación de Norma Mexicana NMX-R-025-SCFI-2015.</li>
        <li>Requisitos críticos.</li>
        <ul class="ul-clean">
          <li>
            2.1. Contar con una política de igualdad laboral y no discriminación
            en el centro de trabajo o equivalente.
          </li>
          <li>
            2.2. Contar con un grupo, comisión o Comité encargado de la
            vigilancia del desarrollo e implementación de prácticas de igualdad
            laboral y no discriminación en el centro de trabajo.
          </li>
          <li>
            2.3. Contar con un proceso de reclutamiento y selección de personal
            sin discriminación y con igualdad de oportunidades.
          </li>
          <li>2.4. Realizar una auditoría interna.</li>
          <li>
            2.5. Medir el clima laboral y no discriminación en el centro de
            trabajo.
          </li>
        </ul>
        <li>Requisitos no críticos.</li>
        <ul class="ul-clean">
          <li>3.1. Existencia de un código de ética o equivalente.</li>
          <li>
            3.2. Garantizar la igualdad salarial y otorgamiento de prestaciones
            y compensaciones al personal.
          </li>
          <li>
            3.3. Contar con procesos de ascenso y permanencia con igualdad de
            oportunidades.
          </li>
          <li>
            3.4. Contar con procesos de formación, capacitación, adiestramiento
            y con igualdad de oportunidades.
          </li>
          <li>
            3.5. Contar con un plan de capacitación y sensibilización en
            igualdad laboral y no discriminación para el personal del centro de
            trabajo.
          </li>
          <li>3.6. Utilizar lenguaje incluyente, no sexista y accesible.</li>
          <li>
            3.7. Realizar acciones para la corresponsabilidad en la vida
            laboral, familiar y personal con igualdad de oportunidades.
          </li>
          <li>3.8. Contar con accesibilidad en los centros de trabajo.</li>
          <li>
            3.9. Mecanismos y regulación para prevenir, atender y sancionar las
            prácticas de discriminación y violencia laboral en el centro de
            trabajo.
          </li>
        </ul>
      </ol>
    </div>
    <div class="table-white"><b>Horas de duración: </b>3 días (9 horas)</div>
    <div class="table-blue">
      <b>Fecha de realización: </b>Del 21, 22 y 23 de marzo de 2023
    </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  