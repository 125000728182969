<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Presupuesto público y derechos humanos de grupos vulnerables
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Presupuesto público y derechos humanos de grupos vulnerables
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/savings.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b
            >Levántate, en pie, defiende tus derechos. Levántate, en pie, no
            dejes de luchar. Bob Marley, cantante jamaicano.</b
          >
        </p>
        <p>
          <b>De acuerdo con el último informe del Coneval</b>, 46.9% de las
          personas con discapacidad presentaban rezago educativo, mientras que
          14.5% de la población sin discapacidad se encontraba en la misma
          situación, aunado a esto, 41.2% de las personas con discapacidad no
          contaba con acceso a la seguridad social y 27.8% presentó carencia por
          acceso a la alimentación. El diagnóstico es simple, aunque cada año
          crecen los recursos destinados a programas sociales, su diseño no
          impacta en las condiciones de vida de los grupos vulnerables.
        </p>
        <p>
          Otro ejemplo claro es la prevalencia de diabetes entre la población
          con discapacidad, situándose en más del doble que la del resto de la
          población. Mientras que 1 de cada 4 personas con discapacidad padecía
          diabetes en 2018, 10.5% de la población total padecía esta enfermedad.
          Situación similar se observa en padecimientos como la hipertensión y
          las enfermedades cardiovasculares.
        </p>
        <p>
          Los directivos encargado de disponer los recursos para desarrollo
          social deben tener clara la existencia de
          <b>grupos históricamente discriminados </b>(adultos mayores, jóvenes,
          mujeres, niñas, niños y adolescentes, personas con discapacidad y
          población indígena), por lo que se hace necesario que en los
          subsecuentes ciclos presupuestales se asignen programas sociales
          específicos que permitan tutelar su derecho a no ser discriminados por
          su condición, lo que nos colocaría frente a un modelo presupuestal de
          avanzada, que protege los derechos humanos de todos los habitantes, a
          partir de sus necesidades y carencias.
        </p>
        <p>
          La asignación presupuestal por tanto no debe hacerse con el simple
          parámetro de medición de la pobreza, deben entrar en juego otros
          factores de vulnerabilidad, de hecho, tenemos grandes avances para
          integrar un gasto a partir de este nuevo enfoque, dado que se ha
          establecido que es una visión corta suponer que la pobreza es solo es
          solo una carencia patrimonial.
        </p>
        <p>
          Los presupuestos públicos deben ser utilizados como una herramienta
          para la redistribución equitativa de los recursos con el objetivo de
          superar las desigualdades sociales, y para avanzar en el sentido de
          que los derechos humanos sean debidamente tutelados. Ello supone que
          los recursos se empleen para hacer más efectivas las estrategias de
          desarrollo, en un marco en el que los derechos humanos sean respetados
          y garantizados a los sectores más excluidos y vulnerados de la
          sociedad.
        </p>
        <p>
          Está en manos de los tomadores de decisiones innovar en el destino del
          gasto público, mediante una efectiva aplicación del modelo de Gestión
          para Resultados (GpR) cuyo instrumento central es el presupuesto
          basado en resultados, dada la importancia de institucionalizar
          mecanismos administrativos que garanticen los derechos humanos y las
          libertades fundamentales, y construir una cultura de respeto y defensa
          de éstos en donde las y los servidores públicos los conozcan y
          promuevan en un afán de transformar las estructuras del gasto social.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>