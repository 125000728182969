<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de asesoramiento</h2>
    <div class="general-text">
      Nos alineamos a programas que tengan que ver con perspectiva de género,
      que contribuyan a la implementación de la política federal, estatal y
      municipal en materia de Igualdad entre Mujeres y Hombres.
    </div>
    <h3 class="my-h3">
      ¿Cuál es el servicio que prestamos en atención a los programas federales y
      estatales con perspectiva de género?
    </h3>
    <div class="general-text">
      <p>
        <b>A jóvenes, adolescentes, niñas y niños </b>
      </p>
      <p>
        Es de carácter formativa, brindando herramientas a las y los
        participantes para su desarrollo para la vida adulta. En ese sentido, se
        brinda información a través de capacitaciones, cursos, talleres, mesas
        de trabajo, con actividades dinámicas, donde cada una/o de las y los
        participantes despejan sus dudas ante los temas expuestos.
      </p>
      <p>
        Los temas que son contemplados en las actividades mencionadas son:
        Educación integral en sexualidad, métodos anticonceptivos, violencia,
        respeto a los derechos humanos, salvaguarda y protección de derechos
        sexuales y reproductivos, prevención de embarazos en adolescentes y
        formulación de agenda donde se considere a este grupo vulnerable.
      </p>
      <p>
        <b>A madres y padres de familia</b>
      </p>
      <p>
        La atención brindada a madres y padres de familia, se vincula
        directamente con aquellas acciones y/o proyectos enfocados a atender
        temas relevantes en niñas, niños y adolescentes; como lo son educación
        integral en sexualidad, perspectiva de género, redes de acompañamiento y
        formulación de agenda.
      </p>
      <p>
        El desarrollo de las actividades se proporciona a la par de niñas, niños
        y adolescentes, y mantiene relevancia en el reconocimiento de los
        derechos de sus hijas e hijos, así como en la capacitación, información
        y sensibilización en temas de educación Integral en sexualidad, redes de
        apoyo, entre otros.
      </p>
      <p>
        <b>A mujeres</b>
      </p>
      <p>
        A través de capacitaciones, talleres y mesas de trabajo enfocadas a
        permear la perspectiva de género en sus familias, con sus parejas y
        centros de trabajo; para contribuir a erradicar prácticas
        discriminatorias, y fomentar espacios de convivencia sanos y libres de
        violencia en contra de ellas.
      </p>
      <p>
        Algunos de los temas que se enfocan en atender a las mujeres son:
        violencia, derechos humanos, perspectiva de género, redes de apoyo.
      </p>
      <p>
        <b>Al servicio público </b>
      </p>
      <p>
        Visión y Estrategia, brinda atención a las y los servidores públicos a
        través de capacitación, implementación de acciones en favor de lograr
        igualdad entre mujeres y hombres, así como contribuir a la
        transversalización de la perspectiva de género.
      </p>
      <p>
        Parte de las acciones implementadas se vinculan con la implementación de
        normas mexicanas en favor de lograr la Igualdad laboral entre mujeres y
        hombres, así como para erradicar prácticas discriminatorias en las
        Instituciones de Gobierno, en sus distintos órdenes.
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeAsesoramientoView",
};
</script>
        
<style>
</style>