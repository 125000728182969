<template>
  <div class="container-fluid">
    <h2 class="my-h2">En que consiste</h2>
    <h3 class="my-h3">Antecedentes de la perspectiva de género</h3>
    <div class="general-text">
      <p>
        En México el movimiento sobre la igualdad de género ha logrado que el
        tema sea colocado en la agenda de las políticas públicas. Así es como,
        el principio fundamental entre hombres y mujeres se integró en 1974, en
        el artículo 4 de la Constitución mexicana, de allí se siguieron
        implementando otras leyes y creando instituciones con Perspectiva de
        Género, entre los principales tenemos: La reforma al artículo 4°
        Constitucional, La Ley del Instituto Nacional de Mujeres, La Ley General
        de Acceso de las Mujeres a una Vida Libre de Violencia, como a
        continuación se describe:
      </p>
      <p style="text-align: center">
        <b> Leyes e instituciones con perspectiva de género </b>
      </p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Ley</th>
            <th scope="col">Año</th>
            <th scope="col">Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Principio Fundamental de igualdad Jurídica entre hombres y mujeres
              Reforma al artículo 4° Constitucional.
            </td>
            <td>1974 DOF 31.12.1974</td>
            <td>
              El varón y la mujer son iguales ante la ley. Esta protegerá la
              organización y el desarrollo de la familia.
            </td>
          </tr>
          <tr>
            <td>
              Creación del programa de integración de la mujer al desarrollo.
            </td>
            <td>1980</td>
            <td>
              Propone un conjunto de iniciativas específicas orientadas a
              promover el mejoramiento de la condición social de las mujeres.
            </td>
          </tr>
          <tr>
            <td>Ley del Instituto Nacional de Mujeres (INMUJERES)</td>
            <td>2001 DOF 12.01.2001</td>
            <td>
              Tiene como función implementar y velar por las consideraciones
              establecidas en los artículos constitucionales.
            </td>
          </tr>
          <tr>
            <td>
              Ley general de acceso de las mujeres a una vida libre de violencia.
            </td>
            <td>
              2006 DOF, 1.02.2007
            </td>
            <td>
              Establece la coordinación entre las Entidades Federativas y los Municipios con el fin de prevenir, sancionar y erradicar la violencia contra las mujeres. 
            </td>
          </tr>
          <tr>
            <td>
              Organización de comunicación e información para la mujer CIMAC.
            </td>
            <td>1988</td>
            <td>
              Tiene como finalidad generar y publicar información noticiosa sobre la condición social de las mujeres, asegurarse que las y los periodistas incorporen los derechos humanos de las mujeres en su trabajo cotidiano.
            </td>
          </tr>
          <tr>
            <td>
              Ley para prevenir, sancionar y erradicar los delitos en materia de trata de personas y para la protección y asistencia de víctimas de estos delitos.
            </td>
            <td>
              2012 DOF 14.06.2012 Reformada el 19.03.2014
            </td>
            <td>
              Tiene como objetivo: Establecer competencias y formas de coordinación para la prevención, investigación, persecución y sanción de los delitos en materia de trata de personas entre los Gobiernos Federal, Estatales, del Distrito Federal y Municipales.
            </td>
          </tr>
          <tr>
            <td>
              Integración del Sistema Nacional para prevenir, atender, sancionar y erradicar la violencia contra las mujeres.
            </td>
            <td>
              3 de abril de 2007
            </td>
            <td>
              Creado en cumplimiento del Art. 36 de la Ley general de acceso de las mujeres a una vida libre de violencia. 
            </td>
          </tr>
          <tr>
            <td>
              Leyes estatales que tutelan el derecho humano de la mujer a una vida libre de violencia (32 estados)
            </td>
            <td>
              Varios años desde 2007
            </td>
            <td>
              Se incorpora la protección de los derechos de las Mujeres en los planes estatales de desarrollo.
            </td>
          </tr>
          <tr>
            <td>
              Fiscalía especial para los delitos en violencia contra las mujeres y trata de personas de la Procuraduría General de la República (FEVIMTRA).
            </td>
            <td>
              31.01.2008 Acuerdo A/024/08 PGR
            </td>
            <td>
              Atención integral a las víctimas de estos delitos para empoderarlas, con el fin de coadyuvar en el proceso de procuración de justicia en el corto plazo, y en el mediano plazo reinsertarse en un ambiente familiar y social libre de violencia para evitar así que vuelva a ser víctima.
            </td>
          </tr>
          <tr>
            <td>
              Comisión especial para conocer y dar seguimiento a las investigaciones relacionadas con los feminicidios de la República Mexicana de la Cámara de Diputados
            </td>
            <td>
              2006 LIX Legislatura Cámara de Diputados
            </td>
            <td>
              Promueve la colaboración con gobiernos de las entidades federativas para conocer la situación del feminicidio en el país y contribuir al fortalecimiento institucional para lograr una mayor incidencia en la prevención, atención, sanción y erradicación de la violencia de género contra las mujeres. 
            </td>
          </tr>
          <tr>
            <td>
              La comisión nacional para prevenir y erradicar la violencia contra las mujeres de la Secretaría de Gobernación.
            </td>
            <td>
              2009 DOF 01.06.2009
            </td>
            <td>
              Coordinar y dar seguimiento, a través de la Secretaría Ejecutiva del Sistema, a los trabajos de promoción y defensa de los derechos humanos de las mujeres, que lleven a cabo las dependencias y entidades de la Administración Pública Federal.
            </td>
          </tr>
          <tr>
            <td>
              Programa nacional para la igualdad entre mujeres y hombres 2009-2012 (PROIGUALDAD).
            </td>
            <td>
              2009 DOF 18.08.2009
            </td>
            <td>
              Operado por INMUJERES, engloba las acciones del Gobierno Federal y establece una serie de líneas básicas de acción y objetivos para garantizar los derechos humanos de las mujeres, la no discriminación, el acceso a la justicia y a la seguridad.
            </td>
          </tr>
          <tr>
            <td>
              Plan Nacional de Desarrollo
            </td>
            <td>
              2013-2018
            </td>
            <td>
              Considerado acciones de gobierno en perspectiva de género para garantizar la igualdad sustantiva de oportunidades entre mujeres y hombres. Es el primer Plan en donde se incorpora una perspectiva de género como principio esencial. 
            </td>
          </tr>
          <tr>
            <td>
              Plan Nacional de Desarrollo
            </td>
            <td>
              2019-2024
            </td>
            <td>
              Eje 2 (Bienestar) y Eje Transversal 1 (Igualdad de género, no discriminación e inclusión). La vía de la implementación de la Perspectiva de Género en el país.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="my-h3">¿Cuál es el marco legal de perspectiva de género?</h3>
    <div class="general-text">
      <p>
        <ul>
          <li>Ley Federal para Prevenir y Eliminar la Discriminación (DOF 11-06-2003).</li>
          <li>Ley General para la Igualdad entre Mujeres y Hombres (DOF 02-08-2006).</li>
          <li>Ley General de Acceso de las Mujeres a una Vida Libre de Violencia (DOF 1-02-2007).</li>
          <li>Ley para Prevenir y Sancionar la Trata de Personas (noviembre de 2007).</li>
          <li>Reglamento de la Ley General de Acceso de las Mujeres a una Vida Libre de Violencia (marzo de 2008).</li>
          <li>Programa Nacional para la equidad entre Mujeres y Hombres 2001-2006.</li>
          <li>Programa Nacional para la Igualdad entre Mujeres y Hombres 2007-2012.</li>
          <li>Programa Nacional para la Igualdad entre Mujeres y Hombres 2013-2018.</li>
          <li>Programa Nacional para la Igualdad entre Mujeres y Hombres 2019-2024.</li>
        </ul>
      </p>
    </div>
    <h3 class="my-h3">¿Qué es perspectiva de género?</h3>
    <div class="general-text">
      <p>
        Es una estrategia destinada a hacer que las preocupaciones y experiencias de las mujeres, así como de los hombres, sean un elemento integrativo de la elaboración, la aplicación, la supervisión y la evaluación de las políticas y los programas en todas las esferas políticas, económicas y sociales; a fin de que las mujeres y los hombres se beneficien por igual y se impida que se perpetúe la desigualdad. El objetivo final es lograr la igualdad [sustantiva] entre los géneros”.
      </p>
      <p>
        Cuando se habla de Perspectiva de Género, se hace alusión a una herramienta conceptual que busca mostrar que las diferencias entre mujeres y hombres se dan no sólo por su determinación biológica, sino también por las diferencias culturales asignadas a los seres humanos.
      </p>
    </div>
    <h3 class="my-h3">¿Cuál es el objetivo de la perspectiva de género?</h3>
    <div class="general-text">
      <p>
        Es promover la igualdad entre mujeres y hombres mediante la visibilización de sus necesidades y garantizando el acceso, la participación y su uso en igualdad de condiciones. <b>Es reducir las brechas</b> de género en las organizaciones, mediante la implementación de un amplio abanico de medidas de igualdad de oportunidades, de acciones afirmativas y de transversalización de género. Romper roles y estereotipos de género.
      </p>
    </div>
    <h3 class="my-h3">¿Cuál es la importancia de aplicar la perspectiva de género?</h3>
    <div class="general-text">
      <p>
        Reside en las posibilidades que ofrece para comprender cómo se produce la discriminación de las mujeres y las vías para transformarla. Además, es necesario entender que la perspectiva de género mejora la vida de las personas, de las sociedades y de los países, enriqueciendo todos los ámbitos productivos, es decir, no se limita solamente a las políticas focalizadas a favor de las mujeres.
      </p>
    </div>
    <h3 class="my-h3">¿Cuáles pueden ser algunas propuestas para fomentar la perspectiva de género?</h3>
    <div class="general-text">
      <p>
        <ul>
          <li>Poniendo freno al sexismo.</li>  
          <li>Dejando de dividir a los hombres y mujeres en función de roles.</li>  
          <li>Fomentando la igualdad en las aulas.</li>  
          <li>Conociendo la terminología y las figuras destacadas.</li> 
          <li>Deconstruyendo estereotipos.</li>  
          <li>Prestando atención a las políticas de en los centros de trabajo.</li> 
          <li>Siendo profesionales comprometidos y comprometidas.</li> 
        </ul>
      </p>
    </div>
    <h3 class="my-h3">Ámbitos en los que se puede aplicar la perspectiva de género</h3>
    <div class="general-text">
      <p>
        <ul>
          <li>
            <b>Trabajo</b>. En el ámbito laboral existen numerosas desigualdades en cuanto a salario, o al acceso a puestos directivos. Para combatir este problema es necesario que se elaboren políticas públicas que apuesten por la transparencia en cuanto a los salarios que se pagan a hombres y mujeres por las empresas en un mismo puesto
          </li>
          <li>
            <b>Salud</b>. En el ámbito de la salud la perspectiva de género se refiere a la necesidad de reconocer las diferencias entre hombres y mujeres, de forma que los resultados y los riesgos sanitarios pueden ser diferentes. Hasta hace poco tiempo la salud pública, en el caso de las mujeres, se enfocaba en el embarazo y el parto, pero es necesario un punto de vista más amplio, de forma que se cuide la salud de la mujer en todo momento. 
          </li>
          <li>
            <b>Educación</b>. Las escuelas pueden ser uno de los principales elementos para educar a las futuras generaciones con perspectiva de género de forma que se corrija cualquier tipo de desigualdad social. En este sentido es fundamental:
          </li>
          <ol type="a">
            <li>
              Favorecer el acceso de las niñas, adolescentes y de las mujeres a la educación.
            </li>
            <li>
              Utilizar materiales didácticos que destaquen por igual las actividades realizadas por hombres y por mujeres.
            </li>
            <li>
              La educación en valores como la igualdad y el respeto.
            </li>
            <li>
              Empoderar a las mujeres para que participen en igualdad en todos los ámbitos.
            </li>
          </ol>
        </ul>
      </p>
    </div>
    <h3 class="my-h3">¿Quiénes son beneficiarios con la perspectiva de género?</h3>
    <div class="general-text">
      <p>
        <ul>
          <li>Mujeres, niñas.</li>
          <li>Adolescentes.</li>
          <li>Hombres, niños.</li>
          <li>Grupos vulnerables.</li>
          <li>Sociedad en general.</li>
        </ul>
      </p>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "EnQueConsisteView",
};
</script>
      
<style>
</style>