<template>
  <div class="container-fluid">
    
    <div class="table-title">
      Nombre del Curso: Formación del equipo auditor
    </div>
    <div class="table-blue">
      <b>Descripción:</b>
      <p>
        La auditoría interna es uno de los procesos más relevantes en los Sistemas de Gestión, ya que permiten revelar áreas de oportunidad, así como desarrollar la mejora continua de los procesos de la Norma.
      </p>
      <p>
        En este curso los asistentes se formarán para efectuar auditorías internas para los centros de trabajo, evaluando el desempeño de su Sistema de Gestión de la NMX-R-025-SCFI-2015.
      </p>
      <p>
        Los participantes comprenderán las mejores prácticas en la realización de las auditorías internas en la Norma NMX-R-025-SCFI-2015, desarrollando habilidades para efectuar auditorías, aprendiendo técnicas que permitirán lograr objetividad en los resultados en una auditoría. Sabrá como verificar la efectividad y oportunidades de mejora en un Sistema de Gestión.
      </p>
    </div>
    <div class="table-white">
      <b>Características:</b>
      <p>
        <b>Dirigido a: </b>Profesionistas, independientes, consultores, académicos, estudiantes, la alta dirección y personal de los centros de trabajo públicos, privados y sociales que estén interesados en implantar o renovar la Norma Mexicana NMX-R-025-SCFI-2015.
      </p>
      <p>
        <b>Evaluación: </b>Al finalizar el curso se llevará a cabo un examen por escrito para determinar el grado de aprovechamiento.
      </p>
      <p>
        <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
      </p>
      <p>
        <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
      </p>
    </div>
    <div class="table-blue">
      <b>Temario:</b>
      <p>
        <ol>
          <li>Revisión de los requisitos de la Norma NMX-R-025-SCFI-2015</li>
          <li>Términos y definiciones</li>
          <li>Requisitos críticos</li>
          <li>Requisitos no críticos</li>
          <li>Principios de auditoría</li>
          <li>Gestión de un programa de auditoría con base a la ISO 19011:2018</li>
          <li>Reuniones de apertura</li>
          <li>Desarrollo de auditorías</li>
          <li>Hallazgo de auditoría y muestreo</li>
          <li>Reuniones de cierre.</li>
          <li>Reporte de auditoría</li>
          <li>Competencia y evaluación de los auditores</li>
          <li>Conclusiones</li>
        </ol>
      </p>
    </div>
    <div class="table-white">
      <b>Horas de duración: </b>6 días 18 horas (3 por día)
    </div>
    <div class="table-blue">
      <b>Fecha de realización: </b>Del 17 al 19 y del 24 al 26 de julio de 2023
    </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  