<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del Taller: Implantación de la Norma Mexicana NMX-R-025-SCFI-2015 en centros de trabajo
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>  
                  <li>
                    Comprender los conceptos, enfoques, métodos y técnicas empleadas para aplicar la Norma Mexicana NMX-R-025-SCFI-2015 en centros de trabajo.
                  </li>
                  <li>
                    Conocer y aprender como requisitar los formatos de la documentación que se debe elaborar y presentar como evidencia durante el desarrollo de una pre auditoría o auditoría para obtener la certificación o renovación del certificado en la Norma Mexicana NMX-R-025-SCFI-2015.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso proporcionará a los participantes los conocimientos necesarios para formular la documentación que se requiere como evidencia durante el proceso de una pre auditoría o auditoría para conseguir la certificación o renovación de certificación en la Norma Mexicana NMX-R-025-SCFI-2015.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Profesionistas, independientes, consultores, académicos, estudiantes, la alta dirección y personal de los centros de trabajo públicos, privados y sociales que estén interesados en aplicar, implantar o renovar su certificado en la Norma Mexicana NMX-R-025-SCFI-2015.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Boque 1. Ventajas de certificación en la Norma Mexicana NMX-R-025-SCFI-2015.</li>
                  <li>Boque 2. Proceso de certificación</li>
                  <ul class="ul-clean">
                    <li>Paso 1. Registro.</li>
                    <li>Paso 2. Cartas de no incumplimiento.</li>
                    <li>Paso 3. Diagnóstico de autoevaluación.</li>
                    <li>Paso 4. Cumplimiento de los requisitos.</li>
                    <ul class="ul-clean">
                      <li>4.1. Requisitos críticos (5).</li>
                      <li>4.2. Requisitos no críticos (9).</li>
                      <li>4.3. Medidas de nivelación.</li>
                    </ul>
                    <li>Paso 5. Pre auditoría y plan de acción.</li>
                    <li>Paso 6. Elección de un organismo de certificación.</li>
                    <li>Paso 7. Auditoría de certificación.</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>10 días 20 horas (2 por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 19 al 23 y del 26 al 30 de junio de 2023
            </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  