<template>
  <div class="container-fluid">
    <div class="table-title">
      Nombre del Curso: Perspectiva de Género y Derechos Humanos de la Mujer y
      el Hombre
    </div>
    <div class="table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Estudiar la génesis y desarrollo del derecho internacional de los
          derechos humanos de las mujeres y hombres, así como el proceso de
          incorporación de las principales figuras jurídicas en la materia.
        </li>
      </ul>
    </div>
    <div class="table-blue">
      <b>Descripción:</b>
      <p>
        Con el curso los participantes podrán reflexionar mediante herramientas
        participativas con el objeto de incorporar la perspectiva de género en
        su ámbito laboral. Los contenidos estudiados te permitirán examinar las
        desigualdades provocadas por las diferencias de sexo sostenidas en una
        estructura patriarcal, con la finalidad de que sean transformadas.
      </p>
      <p>
        Además, los asistentes identificarán las brechas de género con el objeto
        de proponer acciones equitativas que permitan avanzar hacia la igualdad
        sustantiva y conocer herramientas para transversalizar la perspectiva de
        género en los espacios de injerencia.
      </p>
    </div>
    <div class="table-white">
      <b>Características:</b>
      <p>
        <b>Dirigido a: </b>Profesionales del universo de la consultoría
        normativa y del mundo de la gestión pública y privada, que deseen
        adquirir los conocimientos referentes a la perspectiva de género y
        derechos humanos de la mujer y el hombre.
      </p>
      <p>
        <b>Garantía de satisfacción: </b>Avalamos la transmisión de
        conocimientos a través de una metodología que permita elegir la
        información para el diseño de productos o resultados.
      </p>
      <p>
        <b>Constancia: </b>Al término del curso se otorgará una constancia de
        participación con valor curricular, con la insignia de Visión y
        Estrategia.
      </p>
    </div>
    <div class="table-blue">
      <b>Temario:</b>
      <ol>
        <li>Construcción social de género.</li>
        <ul class="ul-clean">
          <li>1.1. Sistema sexo-género.</li>
          <li>1.2. Roles y estereotipos.</li>
        </ul>
        <li>Perspectiva de género</li>
        <ul class="ul-clean">
          <li>2.1. Feminismos.</li>
          <li>2.2. Perspectiva de género.</li>
          <li>2.3. Derechos humanos de las mujeres y los hombres.</li>
        </ul>
        <li>3. Igualdad sustantiva.</li>
        <ul class="ul-clean">
          <li>3.1. Brechas de género</li>
          <li>3.2. Equidad.</li>
          <li>3.3. Igualdad sustantiva.</li>
          <li>3.4. Transversalidad.</li>
        </ul>
      </ol>
    </div>
    <div class="table-white">
      <b>Horas de duración: </b>8 días 16 horas (2 horas por día)
    </div>
    <div class="table-blue">
      <b>Fecha de realización: </b>Del 15 al 19 y 22, 23 y 24 de mayo de 2023
    </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  