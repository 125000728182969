<template>
  <div class="container-fluid">
    <div class="table-title">
      Nombre del Curso: Empoderamiento de las mujeres a través del deporte.
    </div>
    <div class="table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Atender la diferenciación o desigualdad de género, a través del
          empoderamiento que puede aportar el deporte en la vida de niñas,
          jóvenes, adolescentes y mujeres, pretendiendo que a través de la
          práctica de alguna disciplina existan mejoras significativas en la
          salud, bienestar físico y emocional de las personas, promover el
          liderazgo y el desarrollo de las habilidades para trabajar en equipo y
          formar perseverancia y disciplina.
        </li>
      </ul>
    </div>
    <div class="table-blue">
      <b>Descripción:</b>
      <p>
        Las actividades a efectuar serán de carácter formativo, a fin de que las
        participantes despejen la mayor cantidad de dudas, y que la facilitadora
        al compartir su experiencia y logros en su disciplina evoque un deseo de
        liderazgo en las participantes. De esta forma, se espera que la
        conversación cercana y amena entre las partes involucradas promueva la
        formación de liderazgos.
      </p>
      <p>
        Con el curso se logrará que las mujeres se empoderen a través del
        deporte, logran que las participantes se interesen en disciplinas
        deportivas y consideren su práctica; promueven el desarrollo de metas y
        objetivos a través de la inspiración y experiencias compartidas;
        existirá interacción natural y fluida entre las participantes,
        promoviendo un espacio de confianza y de intercambio de ideas; la
        experiencia brindada por una mujer con éxito, resulte emocionante para
        las participantes.
      </p>
    </div>
    <div class="table-white">
      <b>Características:</b>
      <p>
        <b>Dirigido a: </b>Niñas/os, adolescentes y personas interesadas en el
        empoderamiento en perspectiva de género.
      </p>
      <p>
        <b>Garantía de satisfacción: </b>Avalamos la transmisión de
        conocimientos a través de una metodología que permita elegir la
        información para el diseño de productos o resultados.
      </p>
      <p>
        <b>Constancia: </b>Al término del curso se otorgará una constancia de
        participación con valor curricular, con la insignia de Visión y
        Estrategia.
      </p>
    </div>
    <div class="table-blue">
      <b>Temario:</b>
      <ol>
        <li>
          Actividades de carácter formativo que empoderen a la mujer a través
          del deporte, mediante el intercambio de ideas que las motive a ello.
        </li>
      </ol>
    </div>
    <div class="table-white"><b>Horas de duración: </b>3 días (9 horas)</div>
    <div class="table-blue">
      <b>Fecha de realización: </b>Del 10 al 12 de Abril de 2023
    </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  