<template>
  <div class="container-fluid">
    <div class="table-title">
      Nombre del Curso: Escuela de Liderazgo Adolescente.
    </div>
    <div class="table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Participación y fortalecimiento de liderazgos de niñas y adolescentes,
          dotándolas de habilidades que les permitan expresar sus necesidades,
          con énfasis en la educación integral en sexualidad.
        </li>
      </ul>
    </div>
    <div class="table-blue">
      <b>Descripción:</b>
      <p>
        La Escuela de Liderazgo Adolescente, permite desarrollar en las
        participantes una serie de herramientas que les sirvan en sus vidas
        futuras.
      </p>
      <p>
        La Escuela de Liderazgo Adolescente (ELA), forma parte del esfuerzo
        conjunto realizado por la Estrategia Nacional para la Prevención del
        Embarazo en Adolescentes (ENAPEA), la razón de ser de la ELA, es el alto
        número de embarazos registrados en edades tempranas en el país.
      </p>
      <p>
        La incidencia de esta problemática se pretende atender a través de la
        implementación y operación de proyectos, con sustento metodológico y
        proporcionados por personas especialistas en el tema, donde coparticipen
        niñas, niños y adolescentes, madres y padres de familia y autoridades
        escolares, de salud, de procuración de justicia y sociedad civil.
      </p>
      <p>
        La operación de las Escuelas de Liderazgo Adolescente, propicia la
        formación de redes de apoyo en los múltiples círculos en los que se
        desenvuelven NNA. Además de lo anterior, se promueve que las
        participantes se conviertan en agentes de cambio en la comunidad y en
        los centros educativos de los que son parte, repercutiendo de esta forma
        favorablemente en la sociedad.
      </p>
      <p>
        Las actividades de Escuela de Liderazgo Adolescente propician la
        formulación de planes de vida, o proyectos de vida, que servirán de guía
        para que las participantes se planteen metas a lograr en el corto,
        mediano y largo plazo.
      </p>
    </div>
    <div class="table-white">
      <b>Características:</b>
      <p>
        <b>Dirigido a: </b>Niñas, adolescentes y personas interesadas en conocer
        el liderazgo en perspectiva de género.
      </p>
      <p>
        <b>Garantía de satisfacción: </b>Avalamos la transmisión de
        conocimientos a través de una metodología que permita elegir la
        información para el diseño de productos o resultados.
      </p>
      <p>
        <b>Constancia: </b>Al término del curso se otorgará una constancia de
        participación con valor curricular, con la insignia de Visión y
        Estrategia.
      </p>
    </div>
    <div class="table-blue">
      <b>Temario:</b>
      <ol>
        <li>Igualdad de género.</li>
        <li>Empoderamiento femenino.</li>
        <li>Elaboración del plan de vida para adolescentes.</li>
        <li>Liderazgo.</li>
        <li>Identidad.</li>
        <li>Metas en la vida futura.</li>
        <li>Alternativas para el cumplimiento de metas y objetivos de vida.</li>
        <li>Educación Integral en Sexualidad.</li>
        <li>Prevención y Atención de la violencia.</li>
        <li>Salud sexual y reproductiva.</li>
        <li>Matrimonios infantiles.</li>
      </ol>
    </div>
    <div class="table-white"><b>Horas de duración: </b>8 días (16 horas)</div>
    <div class="table-blue">
      <b>Fecha de realización: </b>Del 17 al 21 y del 24 al 26 de Abril de 2023
    </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  