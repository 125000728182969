<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          IGUALDAD SALARIAL, UN PASO MÁS HACIA LA IGUALDAD DE GÉNERO SUSTANTIVA
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      IGUALDAD SALARIAL, UN PASO MÁS HACIA LA IGUALDAD DE GÉNERO SUSTANTIVA
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/igualdad_salarial.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b>
            “Cualquiera puede leer un discurso, pero sólo aquellos con voluntad
            pueden convertir las palabras en acciones”
          </b>
        </p>
        <p>
          En los últimos años se ha hecho un esfuerzo por establecer el mayor
          número de acciones que permitan erradicar la desigualdad de género,
          una problemática compleja que lleva siglos existiendo, sin embargo, el
          avance de los últimos diez años ha sido sorprendente en países donde
          la justicia laboral es una realidad. La actualización de la NMX 025 en
          Igualdad Laboral y No Discriminación en el año 2015 atestigua este
          hecho.
        </p>
        <p>
          <b>
            El primer paso para atender cualquier problemática social es
            reconocer su existencia, es claro que los discursos sobre la
            igualdad no nos llevan a ningún avance si no van acompañados de
            acciones al interior de los centros de trabajo.
          </b>
        </p>
        <p>
          Nuestra sociedad ya tiene una gran conciencia del problema, las
          crecientes disputas en las redes sociales dan cuenta de este hecho,
          ahora son necesarias acciones concretas que permitan cambiar las
          condiciones de desigualdad que viven las mujeres en su vida cotidiana;
          es decir, se requiere consolidar la igualdad de género sustantiva.
        </p>
        <p>
          De acuerdo con la Organización para la Cooperación y el Desarrollo
          Económico (OCDE), en México, la brecha salarial entre mujeres y
          hombres era de 18.8% en 2019 y tristemente es la más amplia de los
          países miembros de la OCDE, muy por encima de la brecha salarial
          promedio identificada en el 13 por ciento. También, en el estudio
          “Discriminación estructural y desigualdad social”, realizado por la
          Secretaría de Gobernación, el Consejo Nacional para Prevenir la
          Discriminación (CONAPRED) y la Comisión Económica para América Latina
          y el Caribe (CEPAL), se destaca que en a pesar de tener un nivel de
          escolaridad y puestos similares, los mexicanos ganan, en una hora, 34%
          más que las mexicanas.
        </p>
        <p>
          Estos datos enmarcan la propuesta de reforma a diferentes leyes que
          actualmente se discute en el Congreso de la Unión, cuya finalidad es
          garantizar que exista una remuneración salarial basada en elementos
          objetivos: conocimientos profesionales, destreza, aptitudes para las
          relaciones interpersonales; los esfuerzos, mental y físico;
          responsabilidades, número de personas a cargo; y condiciones en que se
          realiza el trabajo. Esto con el fin de garantizar la disminución de la
          brecha salarial entre hombres y mujeres.
        </p>
        <p>La iniciativa propone 4 aspectos fundamentales:</p>
        <ol>
          <li>
            Establecer una herramienta efectiva para que las mujeres puedan
            denunciar de manera directa, sin intermediarios, los actos de
            discriminación salarial en el trabajo y obtener una reparación del
            daño. La reforma plantea contar con herramienta institucional
            efectiva, y no una disposición legal enunciativa.
          </li>
          <li>
            Establecer como obligación de los Inspectores del Trabajo;
            monitorear permanentemente las prácticas salariales, para
            identificar actos de discriminación contra las mujeres.
          </li>
          <li>
            Crear el Sistema Público de Monitoreo de Prácticas Salariales y
            Normas del Trabajo, que deberá contener informes detallados sobre
            prácticas discriminatorias y otras violaciones a las condiciones del
            trabajo contra las mujeres. Servirá para identificar a los
            empleadores que cometen actos discriminatorios; y darle seguimiento
            puntual a los casos y denuncias, para que sean sancionados,
            corregidos y no queden impunes.
          </li>
          <li>
            Establecer en la Ley Federal del Trabajo y la Ley Federal de los
            Trabajadores al Servicio del Estado, Reglamentaria del Apartado 8
            del Artículo 123 Constitucional, que los sueldos se fijen con base
            en criterios objetivos.
          </li>
        </ol>
        <p>
          En Visión y Estreategia estamos preparando un Webinar gratuito para
          analizar a detalle los elementos que constituyen esta reforma de ley,
          y cuál será su impacto real en la consolidación de acciones para
          alcanzar una igualdad de género sustantiva. Te invitamos a acompañar
          estas importantes reflexiones.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>