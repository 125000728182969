<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Legisladoras, pero, sobre todo, legislación con perspectiva de género
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Legisladoras, pero, sobre todo, legislación con perspectiva de género
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/justicia.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          “La causa de la mujer es la del hombre: los dos se levantan o sucumben
          juntos”
          <br />
          <em> Alfred Tennyson, poeta ingles </em>
        </p>
        <p>
          <b><em>Los propósitos:</em></b> El 18 de octubre de 2018, el pleno de
          la Cámara de Diputados aprobó que en la plataforma de comunicación,
          así como en la documentación y papelería oficial, se usara la leyenda
          conmemorativa: “LXIV Legislatura de la paridad de género”, para
          celebrar que luego de décadas de demandar un papel más activo de las
          mujeres en la toma de decisiones, por fin ocuparían el 48.2% de los
          escaños parlamentarios.
        </p>
        <p>
          <b><em>Los hechos:</em></b> Infortunadamente la paridad de género en
          el congreso federal no alcanzó para lograr abatir viejas brechas de
          género, incluso se debe decir que la actual legislatura significó un
          retroceso para las mujeres mexicanas más pobres, que perdieron
          recursos del seguro popular destinados a la atención del cáncer de
          mama -el más agresivo de todos los que atacan a las mujeres- y para
          las mujeres trabajadoras también, porque perdieron las estancias
          infantiles que aseguraban una prestación social para sobrellevar otro
          problema de desigualdad social relativo a que las mujeres ganan en
          promedio entre un 20 y un 30% menos que los hombres por el mismo
          trabajo. Como podrán ver, en este párrafo traté de hablar de dos
          problemas provocados por la pasada legislatura y tuve que hablar de
          tres. ¡Vaya que es una complicación ser mujer en México!
        </p>
        <p>
          Las estancias infantiles reflejaban ser una herramienta útil para
          mujeres, hombres y familias, ya que datos del Consejo Nacional de
          Evaluación de la Política de Desarrollo Social (CONEVAL), en su
          informe de monitoreo 2017-2018, mencionaban que 93.9% de las personas
          beneficiadas consideraron que el programa “contribuyó a mejorar su
          calidad de vida y la de sus hijos, además de que brindó la posibilidad
          de contar con empleo, salud mental, salud física e ingresos
          monetarios”.
        </p>
        <p>
          <b><em>Las nuevas expectativas:</em></b> La perspectiva de género es
          definida por Inmujeres como la herramienta para identificar las
          diferencias existentes entre hombres y mujeres, a fin de proponer
          inmediatamente nuevos modelos de socialización que permitan mayor
          igualdad de las mujeres, superando los estereotipos que venimos
          cargando, relativos a que existen territorios vedados para ellas en
          nuestra sociedad, también llamados “techos de cristal”.
        </p>
        <p>
          Hasta ahora la perspectiva de género se ha usado para mejorar la
          condición de la mujer en la salud, la educación y el trabajo, sin
          embargo, debemos empezar a diversificar su uso en otros campos de
          acción como la legislación, pues permitiría no solo recuperar las
          estancias infantiles y los recursos del seguro popular; bien podríamos
          hacer posible también que las leyes que impulsen los hombres y mujeres
          de la nueva legislatura:
        </p>
        <ol>
          <li>
            Establezcan una valoración más justa de los trabajos que hacen
            mujeres y hombres.
          </li>
          <li>Se materialicen en un sistema nacional de cuidados.</li>
          <li>
            Modifiquen aquellas normas y estructuras que provocan desigualdad,
            empezando con la Ley del Seguro Social.
          </li>
          <li>
            Sean capaces de armonizar las leyes laborales con la necesidad de
            fomentar corresponsabilidad con la vida familiar y personal.
          </li>
        </ol>
        <p>
          Aspiramos a tener legisladoras y legisladores que comprendan
          perfectamente las causas de la discriminación hacia las mujeres y las
          combatan mediante leyes específicas todos los días de su gestión.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>