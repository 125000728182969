<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Planes de accesibilidad universal para centros de trabajo del siglo
          XXI
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Planes de accesibilidad universal para centros de trabajo del siglo XXI
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/accessibility.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <em
            >"La única discapacidad es cuando la gente no puede ver el potencial
            humano." Henry Viscandi</em
          >
        </p>
        <p>
          La accesibilidad universal es la cualidad que tienen o se confiere a
          los entornos, en los que se puede disfrutar de bienes o servicios,
          según el contexto dado, con el fin de hacerlos adecuados a las
          capacidades, necesidades y expectativas de todos sus potenciales
          usuarios independientemente de su edad, sexo, origen cultural o grado
          de capacidad.
        </p>
        <p>
          Los centros de trabajo del siglo XXI saben que la accesibilidad
          arquitectónica, el acceso a los bienes y recursos de las
          organizaciones, así como la información y comunicación para las y los
          trabajadores es inequitativa, lo cual deteriora la productividad y las
          relaciones humanas.
        </p>
        <p>
          Todos aquellos lugares tales como edificios, locales, instalaciones y
          áreas, con o sin mobiliario, maquinaria o equipo, deberían permitir a
          sus trabajadores con y sin discapacidad, entrar, desplazarse, salir,
          orientarse y comunicarse de manera segura, autónoma y cómoda para
          realizar sus actividades de producción, comercialización, transporte
          y/o almacenar o prestar servicios.
        </p>
        <p>
          Para que los centros de trabajo pueden alcanzar la accesibilidad es
          necesario la elaboración e implementación de Planes de Accesibilidad.
        </p>
        <p>
          Los planes de accesibilidad tienen el objetivo de hacer accesible de
          MANERA GRADUAL EL ENTORNO EXISTENTE, con la finalidad de que todas las
          personas lo puedan utilizar libre y autónomamente. Los Planes
          evaluarán el nivel de barreras que existen en un espacio determinado,
          definirá las acciones necesarias para adaptarlo, las valorará,
          priorizará y propondrá un plan de etapas para su ejecución.
        </p>
        <p>
          La Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No
          Discriminación incluye los Planes de Accesibilidad como un requisito
          que deben cumplir aquellos centros de trabajo que deseen ser
          certificados, los planes serán evaluados satisfactoriamente si cumplen
          con los siguientes elementos:
        </p>
        <ol>
          <li>Establezca objetivos.</li>
          <li>
            Diseñe las modificaciones necesarias y adecuadas en la
            infraestructura y los servicios.
          </li>
          <li>Asigne responsables de cada una de las acciones a realizar.</li>
          <li>Asigne los recursos necesarios.</li>
          <li>Establezca un calendario.</li>
          <li>Diseñe indicadores de cumplimiento.</li>
        </ol>
        <p>
          La cadena de accesibilidad depende en gran medida del tamaño físico de
          los de espacios de las oficinas, no obstante, los espacios deben
          modificarse para permitir el acceso para quien lo requiera. Esta
          cadena va desde los espacios de circulación horizontal como áreas de
          llegada, entorno periférico de acceso, huecos de paso, vestíbulos,
          corredores y pasillos, espacios de circulación vertical; escaleras
          exteriores e interiores, rampas exteriores e interiores, ascensores,
          plataformas y elevadores especiales y espacios higiénicos sanitarios;
          áreas de aseo, hasta la accesibilidad en los sistemas de comunicación
          e información, en la documentación impresa; encuadernación, papel,
          composición, contraste, información gráfica, lenguaje y equipos
          informáticos; accesibilidad del hardware y software.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>